export const DISTANCE = [
  // {
  //   icon: 'place',
  //   value: 'local',
  //   title: 'Local (coming soon)',
  //   secondary: 'walking or biking distance',
  //   disabled: true,
  //   divider: false,
  // },
  {
    icon: 'place',
    value: 'nearby',
    title: 'Nearby (coming soon)',
    secondary: 'up to 2-hour drive',
    disabled: true,
    divider: false,
  },
  {
    icon: 'place',
    value: 'regional',
    title: 'Regional',
    secondary: 'up to 4-hour drive',
  },
  {
    icon: 'place',
    value: 'continental',
    title: 'Continental',
    secondary: 'up to 6-hour travel time',
  },
  {
    icon: 'place',
    value: 'international',
    title: 'International',
    secondary: 'outside the country',
  },
]

export const TRAVEL_RESTRICTIONS = [
  {
    icon: 'vaccines',
    value: 'fully_vaccinated',
    title: 'Fully vaccinated',
  },
  {
    icon: 'vaccines',
    value: 'fully_vaccinated_with_restrictions',
    title: 'Fully vaccinated with restrictions',
  },
  {
    icon: 'masks',
    value: 'unvaccinated',
    title: 'Unvaccinated',
  },
  {
    icon: 'masks',
    value: 'unvaccinated_with_restrictions',
    title: 'Unvaccinated with restrictions',
  },
]

export const INTERESTS = [
  // {
  //   icon: 'speed',
  //   value: 'personalized',
  //   title: 'Best score',
  //   secondary: 'Matching your travel style'
  // },
  // {
  //   icon: 'favorite_border',
  //   value: 'saved',
  //   title: 'Saved',
  //   divider: true,
  // },
  // {
  //   icon: 'trending_up',
  //   value: 'popular',
  //   title: 'Popular',
  //   header: 'Popular places',
  //   divider: true,
  // },
  {
    icon: 'local_offers',
    value: 'popular',
    title: 'Cheapest',
    header: 'Cheapest places',
    divider: true,
  },
  // {
  //   icon: 'favorite_border',
  //   value: 'saved',
  //   title: 'Saved',
  //   header: 'Saved destinations',

  // },
  // {
  //   icon: 'beach_access',
  //   value: 'beaches',
  //   title: 'Beaches',
  // },
  // {
  //   icon: 'museum',
  //   value: 'museum',
  //   title: 'Museums',
  // },
  // {
  //   icon: 'nightlife',
  //   value: 'nightlife',
  //   title: 'Nightlife',
  // },
  // {
  //   icon: 'map',
  //   value: 'regions',
  //   title: 'Regions',
  // },
  // {
  //   icon: 'park',
  //   value: 'outdoors',
  //   title: 'Outdoors',
  //   header: 'Outdoor places',
  // },
]

export const THINGS_TO_DO = [
  {
    icon: 'trending_up',
    value: 'trending',
    title: 'Popular attractions',
    imageSrc: '/things_to_do/popular.jpg',
  },
  {
    icon: 'beach_access',
    value: 'beach',
    title: 'Beach',
    imageSrc: '/things_to_do/beach.jpg',
  },
  // {
  //   icon: 'sports_bar',
  //   value: 'local_culture',
  //   title: 'Local Culture',
  // },
  {
    icon: 'local_dining',
    value: 'local_cuisine',
    title: 'Local food',
    imageSrc: '/things_to_do/bakery.jpg',
  },
  {
    icon: 'sports_bar',
    value: 'local_drinks',
    title: 'Local drinks',
    imageSrc: '/things_to_do/drinks.jpg',
  },
  {
    icon: 'diamond',
    value: 'luxury',
    title: 'Luxury',
    imageSrc: '/things_to_do/luxury.jpg',
  },
  {
    icon: 'location_city',
    value: 'history',
    title: 'History',
    imageSrc: '/things_to_do/history.jpg',
  },
  {
    icon: 'wine_bar',
    value: 'wine',
    title: 'Wine',
    imageSrc: '/things_to_do/wine.jpg',
  },
  {
    icon: 'spa',
    value: 'spa',
    title: 'Wellness',
    imageSrc: '/things_to_do/spa.jpg',
  },
  // {
  //   icon: 'local_mall',
  //   value: 'shopping',
  //   title: 'Shopping',
  // },
  {
    icon: 'park',
    value: 'outdoors',
    title: 'Outdoors',
    imageSrc: '/things_to_do/mountains.jpg',
  },
  {
    icon: 'museum',
    value: 'museum',
    title: 'Museums',
    imageSrc: '/things_to_do/gallery.jpg',
  },
  {
    icon: 'nightlife',
    value: 'nightlife',
    title: 'Nightlife',
    imageSrc: '/things_to_do/nightlife.jpg',
  },
  {
    icon: 'pets',
    value: 'safary_widlife',
    title: 'Wildlife',
    imageSrc: '/things_to_do/zoo.jpg',
  },
  {
    icon: 'theater_comedy',
    value: 'art_theater',
    title: 'Art',
    imageSrc: '/things_to_do/theater.jpg',
  },
  {
    icon: 'kitesurfing',
    value: 'adventures_sports',
    title: 'Adventures & Sports',
    imageSrc: '/things_to_do/rafting.jpg',
  },
  // {
  //   icon: 'downhill_skiing',
  //   value: 'skiing_snowboarding',
  //   title: 'Skiing or Snowboarding',
  // },

]

export const CUISINES = [
  // {
  //   title: 'African',
  //   imageSrc: '/cuisines/african.jpg',
  //   value: 'african',
  // },
  {
    title: 'American',
    imageSrc: '/cuisines/american.jpg',
    value: 'american',
  },
  {
    title: 'British',
    imageSrc: '/cuisines/british.jpg',
    value: 'british',
  },
  {
    title: 'Caribbean',
    imageSrc: '/cuisines/caribbean.jpg',
    value: 'caribbean',
  },
  {
    title: 'Chinese',
    imageSrc: '/cuisines/chinese.jpg',
    value: 'chinese',
  },
  {
    title: 'East European',
    imageSrc: '/cuisines/east_european.jpg',
    value: 'east_european',
  },
  {
    title: 'French',
    imageSrc: '/cuisines/french.jpg',
    value: 'french',
  },
  {
    title: 'Greek',
    imageSrc: '/cuisines/greek.jpg',
    value: 'greek',
  },
  {
    title: 'Indian',
    imageSrc: '/cuisines/indian.jpg',
    value: 'indian',
  },
  {
    title: 'Irish',
    imageSrc: '/cuisines/irish.jpg',
    value: 'irish',
  },
  {
    title: 'Italian',
    imageSrc: '/cuisines/italian.jpg',
    value: 'italian',
  },
  {
    title: 'Japanese',
    imageSrc: '/cuisines/japanese.jpg',
    value: 'japanese',
  },
  {
    title: 'Korean',
    imageSrc: '/cuisines/korean.jpg',
    value: 'korean',
  },
  {
    title: 'Mexican',
    imageSrc: '/cuisines/mexican.jpg',
    value: 'mexican',
  },
  {
    title: 'Nordic',
    imageSrc: '/cuisines/nordic.jpg',
    value: 'nordic',
  },
  // {
  //   title: 'Pakistani',
  //   imageSrc: '/cuisines/pakistani.jpg',
  //   value: 'pakistani',
  // },
  {
    title: 'Portuguese',
    imageSrc: '/cuisines/portuguese.jpg',
    value: 'portuguese',
  },
  // {
  //   title: 'South American',
  //   imageSrc: '/cuisines/south_american.jpg',
  //   value: 'south_american',
  // },
  {
    title: 'Spanish',
    imageSrc: '/cuisines/spanish.jpg',
    value: 'spanish',
  },
  {
    title: 'Thai',
    imageSrc: '/cuisines/thai.jpg',
    value: 'thai',
  },
  // {
  //   title: 'Turkish',
  //   imageSrc: '/cuisines/turkish.jpg',
  //   value: 'turkish',
  // }
]

export const OCCASIONS = [
  // {
  //   icon: 'route',
  //   title: 'Day trip',
  //   // imageSrc: '/trips/american.jpg',
  //   value: 'day_trip',
  //   costs: 0,
  //   // original_price: 1.99,
  //   description: 'Get an optimized route to your favorite attractions with a detailed itinerary, and export it to your map for easy navigation.',
  //   beta: true,
  // },
  {
    icon: 'place',
    title: 'Trip (one destination)',
    // imageSrc: '/trips/american.jpg',
    value: 'trip',
    costs: 4.99,
    original_price: 9.99,
    description: 'One destination including flight, accommodation, activities and guide',
  },
  {
    icon: 'tour',
    title: 'Tour (multiple destinations)',
    value: 'tour',
    costs: 9.99,
    original_price: 19.99,
    description: 'Plan a tour and visit multiple destinations during one trip',
  },
  {
    icon: 'directions_car',
    title: 'Road trip',
    value: 'road_trip',
    costs: 12.99,
    // original_price: 25.99,
    description: 'Plan an exciting road trip with recommended routes, accommodations, and attractions along the way.',
    disabled: true,
  },
  {
    icon: 'hiking',
    title: 'Activity trip (Skiing, Biking, Tennis,..) ',
    value: 'activity_trip',
    costs: 12.99,
    // original_price:  25.99,
    description: 'Plan an active getaway focused on your favorite sports or activities',
    disabled: true,
  },
  // {
  //   icon: 'event',
  //   title: 'Festival/Event Trip',
  //   value: 'attend_event',
  //   costs: 14,
  //   description: 'Join a trip centered around a specific festival or event, recommending accommodations, and local activities.',
  // },
  // {
  //   icon: 'groups',
  //   title: 'Group trip',
  //   value: 'group_trip',
  //   costs: 49,
  //   description: 'Organize a memorable group trip, with planning for accommodations, activities, and transportation for large parties.'
  // },
  // {
  //   icon: 'villa',
  //   title: 'Company retreat',
  //   value: 'company_retreat',
  //   costs: 49,
  //   description: 'Plan a productive and enjoyable company retreat, including venue selection, activities, and logistics for team building.'
  // },
  {
    icon: 'support_agent',
    title: 'Hire a human travel planner',
    value: 'travel_agent',
    costs: 199,
    description: 'Get planned your trip by a professional travel agent',
    disabled: true,
  },
]

export const PROGRAMS = [
  {
    // icon: 'family_restroom',
    title: 'Organize trip with a travel agent',
    description: 'A travel agent will help you plan and book a trip for you.',
    value: 'free',
    costs: 199,
    pricePer: 'trip',
    disabled: false,
  },
  // {
  //   title: 'Free',
  //   // short: 'Generate 3 city guides for your trip',
  //   description: 'Generate 3 city guides for your trip',
  //   value: 'free',
  //   costs: 0,
  //   // original_price:  19.99,
  //   pricePer: 'month',
  // },
  // {
  //   title: 'Plus',
  //   description: 'Generate 1 trip itinerary, customize it with AI and book it',
  //   value: 'plus',
  //   costs: 14,
  //   // original_price:  19.99,
  //   pricePer: 'month',
  // },
  // {
  //   title: 'Pro',
  //   description: 'Generate 1 trip itinerary, customize it with AI and book it',
  //   value: 'pro',
  //   costs: 59,
  //   // original_price:  19.99,
  //   pricePer: 'month',
  // },
  // {
  //   title: 'Agency',
  //   description: 'Generate 1 trip itinerary, customize it with AI and book it',
  //   value: 'agency',
  //   costs: 249,
  //   // original_price:  19.99,
  //   pricePer: 'month',
  // },
  // {
  //   title: 'Hire an assistant (coming soon)',
  //   description: 'Coming soon',
  //   description: 'A travel assistant will plan a trip for you',
  //   value: 'experienced',
  //   costs: 49,
  //   // pricePer: 'trip',
  //   disabled: true,
  // },
]

export const GROUP_NAMES = [
  {
    icon: 'people',
    title: 'Me & Girlfriend',
  },
  {
    icon: 'people',
    title: 'Me & Boyfriend',
  },
  {
    icon: 'groups',
    title: 'Friends',
  },
  {
    icon: 'family_restroom',
    title: 'Family',
  },
  {
    icon: 'groups',
    title: 'Travel buddies',
  },
  {
    icon: 'groups',
    title: 'Nomads',
  },
  {
    icon: 'home_work',
    title: 'Teammates',
  },
]

export const ITINERARY_ITEM_CATEGORIES = [
  {
    icon: 'hotel',
    name: 'Accommodation',
    value: 'accommodation',
  },
  {
    icon: 'local_activity',
    name: 'Activity',
    value: 'activity',
  },
  {
    icon: 'car_rental',
    name: 'Car rental',
    value: 'car_rental',
  },
  {
    icon: 'event',
    name: 'Event',
    value: 'event',
  },
  {
    icon: 'sticky_note_2',
    name: 'Note',
    value: 'note',
  },
  {
    icon: 'place',
    name: 'Place',
    value: 'place',
  },
  {
    icon: 'restaurant',
    name: 'Restaurant',
    value: 'restaurant',
  },
  {
    icon: 'photo_camera',
    name: 'Thing to do',
    value: 'thing_to_do',
  },
  {
    icon: 'flight_takeoff',
    name: 'Travel',
    value: 'travel',
  },
]

